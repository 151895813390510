import { t } from "i18next";

export const getNavigationLinks = () => [
  // {
  // label: t("dashboard.Dashboard"),
  // destination: "/",
  // },
  {
    label: t("pricing.Price"),
    destination: "/pricing",
  },
  {
    label: t("faqs.Faqs"),
    destination: "/faqs",
  },
  {
    label: t("settings.Profile"),
    destination: "/settings",
  },
];
