import React, { useContext, useEffect } from "react";
import { BlockStack, FormLayout, Text } from "@shopify/polaris";
import { Field, FieldArray, Formik } from "formik";
import { cloneDeep, isEqual } from "lodash";
import { ToastContext } from "@/Context/ToastContext";
import CreateValidationSchema from "./CreateValidationSchema";
import { Add, Delete, DependOn, DynamicSection, SaveButton } from "./FormComponent";

const MainComponent = (props) => {
  const { name, id, field, errors, values, touched } = props;
  let commonProps = {};
  if (field.name) {
    commonProps = {
      id: name ? `${name}.${field.id}` : field.id,
      name: name ? `${name}.${field.name}` : field.name,
      key: name ? `${name}.${field.name}` : field.name,
    };
  } else {
    commonProps = { id: name, name: name, key: name };
  }
  if (field.type === "component") {
    return (
      <Field
        field={{ ...field, ...commonProps }}
        value={values[field.name]}
        error={errors[field.name]}
        touch={touched[field.name]}
        component={field.component}
      />
    );
  } else if (!field.nested) {
    return (
      <Field
        field={{ ...field, ...commonProps }}
        component={DependOn}
        currentObj={values}
        value={values[field.name]}
        error={errors[field.name]}
        touch={touched[field.name]}
        key={field.name}
      />
    );
  } else if (field.nested === "array") {
    field.groupSize = field.groupSize ? field.groupSize : 2;
    return (
      <FieldArray key={field.id} name={commonProps.name}>
        {({ push, remove }) => (
          <React.Fragment key={field.id}>
            <BlockStack gap="400">
              {field.label && <Text as="p">{field.label}</Text>}
              {values[field.name]?.map((nestedItem, index) => (
                <DynamicSection key={field.name + index} field={field}>
                  {!field.hideDeletebtn && <Delete {...field} index={index} remove={remove} />}
                  <FormLayout>
                    {[...Array(Math.ceil(field?.subfields?.length / field?.groupSize))].map((_, gIndex) => (
                      <FormLayout.Group key={gIndex} condensed>
                        {field?.subfields
                          ?.slice(gIndex * field.groupSize, gIndex * field.groupSize + field?.groupSize)
                          .map((subfield) => {
                            return (
                              <MainComponent
                                field={subfield}
                                name={`${commonProps.name}[${index}]`}
                                id={`${commonProps.id}[${index}]`}
                                key={`${commonProps.id}[${index}].${subfield.name}`}
                                values={values[field.name]?.[index] ? values[field.name][index] : {}}
                                errors={errors[field.name]?.[index] ? errors[field.name][index] : {}}
                                touched={touched[field.name]?.[index] ? touched[field.name][index] : {}}
                              />
                            );
                          })}
                      </FormLayout.Group>
                    ))}
                  </FormLayout>
                </DynamicSection>
              ))}
              {!field.hideAddbtn && <Add {...field} push={push} />}
            </BlockStack>
          </React.Fragment>
        )}
      </FieldArray>
    );
  } else if (field.nested === "object") {
    field.groupSize = field.groupSize ? field.groupSize : 2;
    return (
      <React.Fragment key={field.id}>
        <BlockStack gap="200">
          {field.label && <Text as="p">{field.label}</Text>}
          <DynamicSection field={field}>
            <FormLayout>
              {[...Array(Math.ceil(field?.subfields?.length / field?.groupSize))].map((_, gIndex) => (
                <FormLayout.Group key={gIndex} condensed>
                  {field?.subfields
                    ?.slice(gIndex * field.groupSize, gIndex * field.groupSize + field.groupSize)
                    .map((subfield) => (
                      <MainComponent
                        field={subfield}
                        {...commonProps}
                        key={`${commonProps.key}-${subfield.id}`}
                        values={values[field.name] ? values[field.name] : {}}
                        errors={errors[field.name] ? errors[field.name] : {}}
                        touched={touched[field.name] ? touched[field.name] : {}}
                      />
                    ))}
                </FormLayout.Group>
              ))}
            </FormLayout>
          </DynamicSection>
          {field.helpText && (
            <Text as="p" tone="subdued" variant="bodySm">
              {field.helpText}
            </Text>
          )}
        </BlockStack>
      </React.Fragment>
    );
  } else if (field.nested === "group") {
    field.groupSize = field.groupSize ? field.groupSize : 2;
    return (
      <React.Fragment key={field.id}>
        <BlockStack gap="200">
          <Text as="p">{field.label}</Text>
          <DynamicSection field={field}>
            <FormLayout>
              {[...Array(Math.ceil(field?.subfields?.length / field?.groupSize))].map((_, gIndex) => (
                <FormLayout.Group key={gIndex} condensed>
                  {field?.subfields
                    ?.slice(gIndex * field.groupSize, gIndex * field.groupSize + field.groupSize)
                    .map((subfield, index) => {
                      return (
                        <MainComponent
                          field={subfield}
                          {...commonProps}
                          key={index}
                          values={values ? values : {}}
                          errors={errors ? errors : {}}
                          touched={touched ? touched : {}}
                        />
                      );
                    })}
                </FormLayout.Group>
              ))}
            </FormLayout>
          </DynamicSection>
        </BlockStack>
      </React.Fragment>
    );
  }
};

const CommonForm = ({
  formFields,
  initialValues,
  onSubmit,
  onFormChange,
  formRef,
  isSave,
  isPremium,
  noValueChanged = true,
  noCompare = true,
  label,
  enableReinitialize,
}) => {
  const validationSchema = CreateValidationSchema(formFields);
  const { showToast } = useContext(ToastContext);

  return (
    <Formik
      enableReinitialize={enableReinitialize || false}
      initialValues={cloneDeep(initialValues)}
      onSubmit={(values, formikBag) => {
        if (noValueChanged && isEqual(values, initialValues)) {
          showToast("No values are changed. Try changing values.");
        } else {
          onSubmit(values);
        }
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ values, handleSubmit, errors, touched, setFieldValue }) => {
        {
          onFormChange &&
            useEffect(() => {
              if (noCompare && isEqual(values, initialValues)) return;
              onFormChange(values);
            }, [values]);
        }
        return (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              {formFields.map((field, index) => {
                return (
                  <MainComponent field={field} values={values} errors={errors} touched={touched} key={index} />
                );
              })}
              <SaveButton isSave={isSave} isPremium={isPremium} label={label}></SaveButton>
            </FormLayout>
          </form>
        );
      }}
    </Formik>
  );
};

export default CommonForm;
