import { differenceInCalendarMonths, differenceInDays, differenceInYears, formatDistanceToNow } from "date-fns";
import { t } from "i18next";
import { ABLogo, CCLogo, LinkifyLogo, SeoLogo } from "@/Assets/Index";

// Pricing and Discount values :-
const proMonthlyPrice = 2.99;
const proMonthlyDiscount = 2.84;
const proMonthlyDiscountPercent = 5;

const proYearlyPrice = 35.88;
const proYearlyDiscount = 2.09;
const proYearlyDiscountPercent = 30;
export const paidFeatures = [
  {
    text: "Remove BrandMark Helpy",
    icon: "",
  },
  {
    text: "Unlimited Icons",
    icon: "",
  },
  {
    text: "Custom Icon colors",
    icon: "",
  },
  {
    text: "Unlock All positions",
    icon: "",
  },
  {
    text: "Tooltip (Mouse Hover text)",
    icon: "",
  },
  {
    text: "Multi Position",
    icon: "",
  },
  {
    text: "Custom Visiblity",
    icon: "",
  },
  {
    text: "Custom Style",
    icon: "",
  },
  {
    text: "Design Bar",
    icon: "",
  },
  {
    text: "24/7 instant Support",
    icon: "",
  },
];

export const YearlyFeatures = [
  {
    text: "Remove BrandMark Helpy",
    icon: "",
  },
  {
    text: "Unlimited Icons",
    icon: "",
  },
  {
    text: "Custom Icon colors",
    icon: "",
  },
  {
    text: "Unlock All positions",
    icon: "",
  },
  {
    text: "Tooltip (Mouse Hover text)",
    icon: "",
  },
  {
    text: "Multi Position",
    icon: "",
  },
  {
    text: "Custom Visiblity",
    icon: "",
  },
  {
    text: "Custom Style",
    icon: "",
  },
  {
    text: "Design Bar",
    icon: "",
  },
  {
    text: "24/7 instant Support",
    icon: "",
  },
];

export const freeFeatures =
  process.env.ISFREEAPP == 0
    ? [
        {
          text: "BrandMark Helpy",
          icon: "",
        },
        {
          text: "Two Icons",
          icon: "",
        },
        {
          text: "Limited Design changes",
          icon: "",
        },
        {
          text: "Icons with border",
          icon: "",
        },
        {
          text: "Animations",
          icon: "",
        },
        {
          text: "Unlimited Icons",
          icon: "XIcon",
        },
        {
          text: "Custom Icon colors",
          icon: "XIcon",
        },
        {
          text: "Unlock All positions",
          icon: "XIcon",
        },
        {
          text: "Multi Position",
          icon: "XIcon",
        },
        {
          text: "Custom Style",
          icon: "XIcon",
        },
        {
          text: "Design Bar",
          icon: "XIcon",
        },
      ]
    : [
        {
          text: "Remove BrandMark Helpy",
          icon: "",
        },
        {
          text: "Unlimited Icons",
          icon: "",
        },
        {
          text: "Custom Icon colors",
          icon: "",
        },
        {
          text: "Unlock All positions",
          icon: "",
        },
        {
          text: "Tooltip (Mouse Hover text)",
          icon: "",
        },
        {
          text: "Multi Position",
          icon: "",
        },
        {
          text: "Custom Visiblity",
          icon: "",
        },
        {
          text: "Custom Style",
          icon: "",
        },
        {
          text: "Design Bar",
          icon: "",
        },
        {
          text: "24/7 instant Support",
          icon: "",
        },
      ];

export const featureList = {
  Free: freeFeatures,
  "Premium-Monthly": paidFeatures,
  "Premium-Yearly": YearlyFeatures,
  "Premium-Lifetime": paidFeatures,
  "Pro-Monthly": paidFeatures,
  "Pro-Yearly": YearlyFeatures,
  "Pro-Lifetime": paidFeatures,
  "Default-feature": paidFeatures,
  Premium100: paidFeatures,
  Pro100: paidFeatures,
};
export const interval = {
  "Premium-Monthly": "EVERY_30_DAYS",
  "Premium-Yearly": "ANNUAL",
  "Pro-Monthly": "EVERY_30_DAYS",
  "Pro-Yearly": "ANNUAL",
  Default: "",
};

const monthlyCodes = [
  {
    name: "Promotional",
    code: "PE4ACENT",
    type: "percentage", // can be (amount|percentage)
    value: 5,
  },
  {
    name: "Promotional",
    code: "OS1HOR",
    type: "percentage",
    value: 10,
  },
  {
    name: "Promotional",
    code: "SO2ULA",
    type: "percentage",
    value: 15,
  },
  {
    name: "Promotional",
    code: "TG3KSD",
    type: "percentage",
    value: 20,
  },
  {
    name: "Promotional",
    code: "RU4LIX",
    type: "percentage",
    value: 25,
  },
  {
    name: "Promotional",
    code: "BW5JAK",
    type: "percentage",
    value: 30,
  },
  {
    name: "Promotional",
    code: "SI6LENPM",
    type: "percentage",
    value: 35,
  },
  {
    name: "Promotional",
    code: "IN0NERDP",
    type: "percentage",
    value: 40,
  },
  {
    name: "Promotional",
    code: "AW3AKEPS",
    type: "percentage",
    value: 45,
  },
  {
    name: "Promotional",
    code: "EN7GHLIG",
    type: "percentage",
    value: 50,
  },
];

const yearlyCodes = [
  {
    name: "Promotional",
    code: "PE4ACENT",
    type: "percentage",
    value: 25,
  },
  {
    name: "Promotional",
    code: "OS1HOR",
    type: "percentage",
    value: 30,
  },
  {
    name: "Promotional",
    code: "SO2ULA",
    type: "percentage",
    value: 35,
  },
  {
    name: "Promotional",
    code: "TG3KSD",
    type: "percentage",
    value: 40,
  },
  {
    name: "Promotional",
    code: "RU4LIX",
    type: "percentage",
    value: 45,
  },
  {
    name: "Promotional",
    code: "BW5JAK",
    type: "percentage",
    value: 50,
  },
];

export const getPlansData = () => [
  {
    type: "free",
    id: "Free",
    name: "Free",
    price: 0,
    isHidden: false,
    features: [...featureList["Free"]],
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Premium-Monthly",
    name: "Premium",
    price: proMonthlyPrice,
    isHidden:
      'hasBillingButton ? profileData?.plan_name !== "basic" || !isFirstButtonActive : profileData?.plan_name !== "basic"', //value true or false
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Premium-Monthly"]],
    discounts: [...monthlyCodes],
    trial: {
      days: 7,
    },
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", //it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Premium-Yearly",
    name: "Premium Yearly",
    isHidden:
      'hasBillingButton ? profileData?.plan_name !== "basic" || isFirstButtonActive : profileData?.plan_name !== "basic"', //value true or false
    monthlyPrice: proMonthlyPrice,
    price: proYearlyPrice,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Premium-Yearly"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: proYearlyDiscount,
    initialDiscountObject: { type: "percentage", value: 30 },
    discountPercent: 30,
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited)  for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Pro-Monthly",
    name: "Pro",
    isHidden:
      'hasBillingButton ? profileData?.plan_name === "basic" || !isFirstButtonActive : profileData?.plan_name === "basic" ', //value true or false
    price: proMonthlyPrice,
    initialDiscountPrice: proMonthlyDiscount,
    initialDiscountObject: { type: "percentage", value: proMonthlyDiscountPercent },
    discountPercent: proMonthlyDiscountPercent,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Monthly"]],
    trial: {
      days: 7,
    },
    discounts: [...monthlyCodes],
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Pro-Yearly",
    name: "Pro Yearly",
    isHidden:
      'hasBillingButton?profileData?.plan_name === "basic" || isFirstButtonActive : profileData?.plan_name === "basic"', //value true or false
    monthlyPrice: proMonthlyPrice,
    price: proYearlyPrice,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Yearly"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: proYearlyDiscount,
    initialDiscountObject: { type: "percentage", value: proYearlyDiscountPercent },
    discountPercent: proYearlyDiscountPercent,
  },
];

export const promocodeFormFields = [
  {
    id: "promocode",
    name: "promocode",
    labelPosition: "right",
    type: "text",
    errormsg: "Promocode is required",
    placeholder: "Enter Promocode",
  },
];

export const initialValues = {
  promocode: "",
};

export const formFieldsCancelReason = [
  {
    id: "cancelReason",
    name: "cancelReason",
    label: "Please Choose a Cancellation Reason",
    nested: "object",
    groupSize: 2,
    section: false,
    subfields: [
      {
        radioId: "noLongerNeeded",
        id: "noLongerNeeded",
        name: "reason",
        label: "No Longer Needed?",
        type: "radio",
      },
      {
        radioId: "dontWork",
        id: "dontWork",
        name: "reason",
        label: "Didn't Meet Expectations",
        type: "radio",
      },
      {
        radioId: "costConcerns",
        id: "costConcerns",
        name: "reason",
        label: "Cost Concerns",
        type: "radio",
      },
      {
        radioId: "technicalIssues",
        id: "technicalIssues",
        name: "reason",
        label: "Technical Issues",
        type: "radio",
      },
      {
        radioId: "foundAlternative",
        id: "foundAlternative",
        name: "reason",
        label: "Found an Alternative",
        type: "radio",
      },
      {
        radioId: "complexity",
        id: "complexity",
        name: "reason",
        label: "Complexity",
        type: "radio",
      },
      {
        radioId: "lackFeatures",
        id: "lackFeatures",
        name: "reason",
        label: "Lack of Features",
        type: "radio",
      },
      {
        radioId: "poorCustomerSupport",
        id: "poorCustomerSupport",
        name: "reason",
        label: "Poor Customer Support",
        type: "radio",
      },
      {
        radioId: "businessClosure",
        id: "businessClosure",
        name: "reason",
        label: "Business Closure or Change",
        type: "radio",
      },
      {
        radioId: "temporaryPause",
        id: "temporaryPause",
        name: "reason",
        label: "Temporary Pause",
        type: "radio",
      },
      {
        radioId: "performanceIssues",
        id: "performanceIssues",
        name: "reason",
        label: "Performance Issues",
        type: "radio",
      },
      {
        radioId: "other",
        id: "other",
        name: "reason",
        label: "Other",
        type: "radio",
      },
      {
        id: "value",
        name: "value",
        label: "Mention Your Specific Reason Here",
        labelPosition: "left",
        type: "text",
      },
    ],
  },
];

export const cancelReasonInitialValues = {
  cancelReason: {
    reason: "noLongerNeeded",
    value: "",
  },
};

const dateWisePrice = (date, billingInterval) => {
  const day = differenceInDays(new Date(), new Date(date));
  if (day <= 60) {
    return billingInterval === "Year" ? 2.29 : 2.49;
  } else if (day > 60 && day <= 180) {
    return billingInterval === "Year" ? 2.39 : 2.69;
  } else if (day > 180 && day <= 365) {
    return billingInterval === "Year" ? 2.49 : 2.79;
  } else {
    return billingInterval === "Year" ? 2.59 : 2.84;
  }
};

export const dateWisePriceObj = (date, plan) => {
  const { billingInterval } = plan;
  const monthlyPrice = proMonthlyPrice;
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / proMonthlyPrice);
  let finalObj = {};
  if (finalPrice !== proMonthlyPrice) {
    finalObj = {
      initialDiscountPrice: finalPrice,
      initialDiscountObject: { type: "percentage", value: persent },
      discountPercent: persent,
    };
  }
  const price = billingInterval === "Year" ? monthlyPrice * 12 : monthlyPrice;
  if (plan?.id !== "Free") {
    return billingInterval === "Year" ? { price, monthlyPrice, ...finalObj } : { price, ...finalObj };
  } else {
    return {};
  }
};
export const dateWisePersent = (date, isFirstButtonActive) => {
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / proMonthlyPrice);
  return persent > 0 ? persent : false;
};

const makeBanner = (distanceText, persent, bannerKey) => {
  const bannerMsg = `${t(`pricing.dateWiseBanner.${bannerKey}MsgStart`)} ${distanceText}. ${t(
    `pricing.dateWiseBanner.${bannerKey}MsgMiddle`
  )} ${persent}% ${t(`pricing.dateWiseBanner.${bannerKey}MsgEnd`)}`;
  const bannerTitle = `${t(`pricing.dateWiseBanner.${bannerKey}TitleStart`)} ${persent}% ${t(
    `pricing.dateWiseBanner.${bannerKey}TitleEnd`
  )} ${distanceText}.`;
  return { bannerMsg, bannerTitle };
};

export const dateWisePriceBanner = (date, isFirstButtonActive) => {
  const day = differenceInDays(new Date(), new Date(date));
  const distanceText = formatDistanceToNow(new Date(date), { addSuffix: true });
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / proMonthlyPrice);

  if (day <= 60) {
    return makeBanner(distanceText, persent, "low");
  } else if (day > 60 && day <= 180) {
    return makeBanner(distanceText, persent, "medium");
  } else if (day > 180 && day <= 365) {
    return makeBanner(distanceText, persent, "important");
  } else {
    let bannerMsg = ``;
    const differenceInMonth = differenceInCalendarMonths(new Date(), new Date(date));
    const differenceInYear = differenceInYears(new Date(), new Date(date));
    const finalMonth = differenceInMonth - differenceInYear * 12;
    const monthYearText = `${differenceInYear} ${t("pricing.dateWiseBanner.year and")} ${finalMonth} ${t(
      "pricing.dateWiseBanner.month"
    )}`;
    if (persent > 0) {
      bannerMsg = `${t("pricing.dateWiseBanner.veryImportantMsgStart")}  ${monthYearText} ${t(
        "pricing.dateWiseBanner.ago"
      )}. ${t("pricing.dateWiseBanner.veryImportantMsgMiddle")} ${persent}% ${t(
        "pricing.dateWiseBanner.veryImportantMsgEnd"
      )}`;
    } else {
      bannerMsg = `${t("pricing.dateWiseBanner.generalMsg")}`;
    }
    const bannerTitle = `${t("pricing.dateWiseBanner.veryImportantTitleStart")} ${monthYearText} ${t(
      "pricing.dateWiseBanner.veryImportantTitleEnd"
    )}.`;
    return { bannerMsg, bannerTitle };
  }
};

export const AppList = [
  {
    title: "Webrex SEO: AI, Speed & Schema",
    tagLine: "Optimize your Store by align correct SEO to improve organic ranking, boost visibility",
    link: "https://apps.shopify.com/breadcrumbs-schemas?source=SEO-app",
    image: SeoLogo,
    starRate: 4.9,
    reviweCount: 437,
    popular: true,
    // hide: true,
  },
  {
    title: "WebRex Multi Announcement Bar",
    tagLine: "Boost sales with customizable bars for announcements, free shipping, and countdown timers",
    link: "https://apps.shopify.com/announcement-bar-with-slider?source=SEO-app",
    image: ABLogo,
    starRate: 4.9,
    reviweCount: 436,
    // hide: true,
    // popular: true,
  },
  {
    title: "Linkify ‑ Backlink SEO Booster",
    tagLine: "Supercharge SEO with top-tier backlinks from high DA sites. Boost rankings, traffic, and authority",
    link: "https://apps.shopify.com/linkify-app?source=SEO-app",
    image: LinkifyLogo,
    starRate: 5.0,
    reviweCount: 8,
    hide: true,
    // popular: true,
  },
  {
    title: "Webrex ‑ Currency Converter",
    tagLine: "Solution for your International Selling that will help to show converted prices, local currency",
    link: "https://apps.shopify.com/currency-converter-11?source=SEO-app",
    image: CCLogo,
    starRate: 4.9,
    reviweCount: 306,
    // hide: true,
    // popular: true,
  },
];
